<template>
  <a-modal
    width="800px"
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
  >
    <h6 class="mt-2">
      Ubah Mapping Gudang
    </h6>
    <a-spin :spinning="loading" tip="Mohon tunggu...">
      <a-table
        :columns="columns"
        :data-source="tableMappingWarehouseList"
        :pagination="false"
      >
        <template slot="slotWarehouseChannel" slot-scope="text, record">
          <div>{{ record?.warehouseChannelName || '-' }}</div>
        </template>
        <template slot="slotWarehouseMaster" slot-scope="text, record, index">
          <template v-if="record?.edit">
            <a-select :value="text" class="w-100" placeholder="Pilih gudang" @change="changeWarehouseMaster($event, index)">
              <a-select-option v-for="({ warehouseId, warehouseName }, idx) in listWarehouseMaster" :key="idx" :value="warehouseId">
                {{ warehouseName }}
              </a-select-option>
            </a-select>
          </template>
          <template v-else>
            <div v-if="record?.warehouseMasterName">
              {{ record?.warehouseMasterName }}
            </div>
            <div v-else class="text-muted">
              <i>Belum ada gudang yang terhubung</i>
            </div>
          </template>
        </template>
        <template slot="slotStatus" slot-scope="text, record">
          <template v-if="record?.warehouseMasterName && record?.warehouseChannelName">
            <a-tag color="green">
              connected
            </a-tag>
          </template>
          <template v-else>
            <a-tag color="red">
              not connected
            </a-tag>
          </template>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <template v-if="!record.edit">
            <a-tooltip v-if="record.mappingId" placement="top">
              <template slot="title">
                <span>Ubah Sinkronisasi Gudang</span>
              </template>
              <a-button type="secondary" @click.prevent="handleEdit(record, index)">
                <a-icon type="edit" />
              </a-button>
            </a-tooltip>
            <a-tooltip v-else placement="top">
              <template slot="title">
                <span>Tambah Sinkronisasi Gudang</span>
              </template>
              <a-button type="secondary" @click.prevent="handleEdit(record, index)">
                <a-icon type="plus" />
              </a-button>
            </a-tooltip>
            <a-tooltip v-if="record.warehouseMasterName" placement="top">
              <template slot="title">
                <span>Putuskan Sinkronisasi Gudang</span>
              </template>
              <a-popconfirm
                placement="bottom"
                title="Apakah kamu yakin ingin memutuskan sinkronisasi gudang?"
                description=""
                ok-text="Ya, putuskan sinkronisasi"
                cancel-text="Tidak"
                @confirm="handleDisconnect(record, index)"
              >
                <a-button class="ml-2" type="">
                  <a-icon type="disconnect" />
                </a-button>
              </a-popconfirm>
            </a-tooltip>
          </template>
          <template v-else>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Simpan & Sinkronisasi Gudang</span>
              </template>
              <a-button type="secondary" @click.prevent="handleSave(record, index)">
                <a-icon type="check" />
              </a-button>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Batalkan</span>
              </template>
              <a-button class="ml-2" type="" @click.prevent="handleCancel(record, index)">
                <a-icon type="close" />
              </a-button>
            </a-tooltip>
          </template>
        </template>
      </a-table>
    </a-spin>
    <!-- <template #footer>
      <a-button key="back" :loading="loading" class="mr-2" @click="closeModal()">
        Batalkan
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="submitModal()">
        Ya, Putuskan Koneksi Store
      </a-button>
    </template> -->
  </a-modal>
</template>

<script>

import { getShopeeWarehouseChannelList, getWarehouseShopee } from '@/api/channels/shopee'
import { disconnecMappingWarehouseChannel, connectMappingWarehouseChannel, updateMappingWarehouseChannel } from '@/api/channels/index'
import { getWarehouseList } from '@/api/warehouse'

export default {
  name: 'ModalEditWarehouse',
  props: {
    visible: Boolean,
    back: Function,
    data: Object,
  },
  data() {
    return {
      columns: [
        {
          title: 'Gudang Channel',
          dataIndex: 'warehouseChannelId',
          width: 200,
          scopedSlots: {
            customRender: 'slotWarehouseChannel',
          },
        },
        {
          title: 'Gudang Master',
          dataIndex: 'warehouseMasterId',
          scopedSlots: {
            customRender: 'slotWarehouseMaster',
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: 100,
          scopedSlots: {
            customRender: 'slotStatus',
          },
        },
        {
          title: 'Action',
          dataIndex: 'action',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      tableMappingWarehouseList: [],
      listWarehouseMaster: [],
      listWarehouseChannel: [],
      loading: false,
    }
  },
  computed: {
  },
  watch: {
    'visible': {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value) {
          this.fetchInitData()
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('onCancel', false)
    },
    submitModal() {
      this.$emit('onOk', this.data)
    },
    fetchInitData() {
      this.loading = true
      const promises = []
      const { id } = this.data
      const { business_id } = this.$route.query
      
      promises.push(
        getShopeeWarehouseChannelList({
          channel_id: id,
          // channel_id: 2321,
        })
        .then(({ data }) => {
          if (data) {
            return data
          }
          return null
        })
        .catch((err) => {
          return null
          console.error(err)
        }),
      )
      promises.push(
        getWarehouseList({
          business_id: business_id,
        })
        .then(({ data }) => {
          if (data) {
            return data
          }
          return null
        })
        .catch((err) => {
          console.error(err)
          return null
        }),
      )
      promises.push(
        getWarehouseShopee({
          business_id: business_id,
          channel_id: id,
        })
        .then(({ data }) => {
          if (data?.length > 0 ) {
            return data
          }
          return null
        })
        .catch((err) => {
          console.error(err)
          return null
        }),
      )
      Promise.all(promises)
        .then(([resWarehouseChannelList, resWarehouseMasterList, resMappingWarehouseShopee]) => {
          let newWarehouseChannelList = []
          let newWarehouseMasterList = []
          if (resWarehouseChannelList) {
            const { response } = resWarehouseChannelList
            newWarehouseChannelList = response.map(item => {
              return {
                warehouseId: item.locationId,
                warehouseName: item.warehouseName,
              }
            })
          } else {
            newWarehouseChannelList = [{
              warehouseId: '',
              warehouseName: 'Default Warehouse',
            }]
          }
          this.listWarehouseChannel = newWarehouseChannelList
          const { data } = resWarehouseMasterList
          if (data?.length > 0) {
            newWarehouseMasterList = data.map(item => {
              return {
                warehouseId: item.id,
                warehouseName: item.name,
              }
            })
            this.listWarehouseMaster = newWarehouseMasterList
          }

          if (newWarehouseChannelList?.length > 0) {
            const newMappingWarehouseShopee = newWarehouseChannelList.map(item => {
              const findWarehouseMaster = resMappingWarehouseShopee?.find(warehouse => warehouse.warehouse_channel_id === item.warehouseId)
              return {
                channelId: findWarehouseMaster?.channel_id,
                channelCode: findWarehouseMaster?.channel_code,
                channelName: findWarehouseMaster?.channel_name,
                mappingId: findWarehouseMaster?.id,
                warehouseMasterId: findWarehouseMaster?.warehouse_master_id,
                warehouseMasterName: findWarehouseMaster?.warehouse_master_name,
                warehouseChannelId: item.warehouseId,
                warehouseChannelName: item.warehouseName,
              }
            })
            this.tableMappingWarehouseList = newMappingWarehouseShopee
          }
        })
        .catch((err) => {
          console.error(err)
        })
      .finally(() => this.loading = false)
    },
    handleEdit(record, index) {
      const newList = [...this.tableMappingWarehouseList]
      newList[index].edit = true
      this.tableMappingWarehouseList = newList
    },
    handleDisconnect(record) {
      this.loading = true
      const { channelId, mappingId, channelCode } = record
      const { business_id } = this.$route.query
      const bodyReq = {
        business_id: business_id,
        channel_code: channelCode,
        data: {
          channel_id: channelId,
          mapped_ids: [
            mappingId,
          ],
        },
      }
      disconnecMappingWarehouseChannel(bodyReq)
        .then(({ data }) => {
          this.fetchInitData()
          this.$notification.success({
            message: 'Berhasil memutuskan sinkronisasi gudang',
          })
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    },
    handleSave(record) {
      if (record?.mappingId) {
        this.onUpdateMappingWarehouseChannel(record)
      } else {
        this.onAddMappingWarehouseChannel(record)
      }
    },
    onAddMappingWarehouseChannel(record) {
      this.loading = true
      const { id: channel_id, sales_channel } = this.data
      const { code, name } = sales_channel
      const { business_id } = this.$route.query
      const { warehouseChannelId, warehouseChannelName, warehouseMasterId } = record
      const bodyReq = {
        business_id: business_id,
        channel_code: code,
        data: {
          channel: {
            id: channel_id,
            code,
            name,
          },
          data: [
            {
              warehouse_id: warehouseMasterId,
              warehouse_channel: {
                id: warehouseChannelId,
                name: warehouseChannelName,
              },
            },
          ],
        },
      }
      connectMappingWarehouseChannel(bodyReq)
        .then(({ data }) => {
          this.fetchInitData()
          this.$notification.success({
            message: 'Berhasil menambahkan sinkronisi gudang',
          })
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    },
    onUpdateMappingWarehouseChannel(record) {
      this.loading = true
      const { business_id } = this.$route.query
      const {
        channelCode,
        warehouseChannelId,
        warehouseChannelName,
        warehouseMasterId,
        warehouseMasterName,
        channelId,
        channelName,
        mappingId,
      } = record
      const bodyReq = {
        business_id: business_id,
        channel_code: channelCode,
        mapping_id: mappingId,
        data: {
          channel: {
            id: channelId,
            code: channelCode,
            name: channelName,
          },
          warehouse_master: {
            id: warehouseMasterId,
            name: warehouseMasterName,
          },
          warehouse_channel: {
            id: warehouseChannelId,
            name: warehouseChannelName,
          },
        },
      }
      updateMappingWarehouseChannel(bodyReq)
        .then(() => {
          this.fetchInitData()
          this.$notification.success({
            message: 'Berhasil mengubah sinkronisi gudang',
          })
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    },
    handleCancel(record, index) {
      const newList = [...this.tableMappingWarehouseList]
      newList[index].edit = false
      this.tableMappingWarehouseList = newList
    },
    changeWarehouseMaster(value, index) {
      const newList = [...this.tableMappingWarehouseList]
      const findWhsMasterName = this.listWarehouseMaster.find((whs) => whs.warehouseId === value)
      newList[index].warehouseMasterId = value
      newList[index].warehouseMasterName = findWhsMasterName?.warehouseName
      this.tableMappingWarehouseList = newList
    },
  },
}
</script>

<style lang="scss" module>
</style>